<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Product List</h3>

                <div class="card-tools">
                  <router-link to="/products/add"  @click.native="$router.go()" class="btn btn-sm btn-primary"><i class="fa fa-plus-square" id="AddProduct" v-show="can('product_create')"></i> Add New</router-link>
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="product in products.data" :key="product.id">

                      <td>{{product.id}}</td>
                      <td>{{product.title}}</td>
                      <td>{{product.body_html | truncate(30, '...')}}</td>
                      <td>{{product.type}}</td>
                      <td>{{product.price}}</td>
                      <!-- <td><img v-bind:src="'/' + product.photo" width="100" alt="product"></td> -->
                      <td>
                        
                       <!--  <a href="#" @click="editModal(product)">
                            <i class="fa fa-edit blue"></i>
                        </a> -->
                        <router-link :to="{name: 'productedit', params: { id: product.id}}"  @click.native="$router.go()" class="fa fa-edit blue editProduct" v-show="can('product_edit')">
                        </router-link>

                        <a href="#" @click="deleteProduct(product.id)" v-show="can('product_delete')" class="deleteProduct">
                            <i class="fa fa-trash red"></i>
                        </a>
                          <button type="button" class="btn btn-sm btn-primary syncProduct" @click="editModal(product)" v-show="can('product_sync')">
                            <i class="fas fa-sync-alt"></i>
                         </button>
                        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                              <div class="modal-content">
                              <div class="modal-header">
                                  <h5 class="modal-title">Sync Shopify Store</h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                              </div> 
                              <form @submit.prevent="SyncProduct()">
                                  <div class="modal-body">
                                    <input type="hidden" v-model="form.id">
                                      <div class="form-group">

                                          <label>Select Shopify Store</label>
                                          <select class="form-control" v-model="form.shop_id">
                                            <option 
                                                v-for="(shop,index) in shops" :key="index"
                                                :value="index"
                                                :selected="index == form.shop_id">{{ shop }}</option>
                                          </select>
                                          <has-error :form="form" field="shop_id"></has-error>
                                      </div>
                                      
                                  </div>
                                  <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                      <button type="submit" class="btn btn-primary">Sync</button>
                                  </div>
                                </form>
                              </div>
                          </div>
                      </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="products" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                editmode: false,
                products : {},
                form: new Form({
                    id : '',
                    shop_id : '',
                }),
                categories: [],
                shops: [],
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {
          can(permissionName)
            {
                switch (permissionName) {
                  case 'product_create':
                  axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                           
                            $('#AddProduct').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('#AddProduct').hide();
                            return false;
                        }
                    });
                  break;
                  case 'product_edit':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.editProduct').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.editProduct').hide();
                            return false;
                        }
                        
                    });
                  break;
                  case 'product_delete':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.deleteProduct').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.deleteProduct').hide();
                            return false;
                        }
                        
                    });
                  break;
                  case 'product_sync':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.syncProduct').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.syncProduct').hide();
                            return false;
                        }
                        
                    });
                  break;
                  
                }

            },
          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/product?page=' + page).then(({ data }) => (this.products = data.data, this.shops = data.message));

              this.$Progress.finish();
          },
          loadProducts(){

            // if(this.$gate.isAdmin()){
              axios.get("api/product").then(({ data }) => (this.products = data.data, this.shops = data.message));
              console.log(this.products);
            // }
          },
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
          loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id };
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          SyncProduct(){
              this.$Progress.start();
              console.log(this.form);
              axios.post('api/product/syncProduct',this.form)
              .then((data)=>{
                console.log(data);
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadProducts();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteProduct(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              axios.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProducts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadProducts();
            this.loadCategories();
            this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
