<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card" v-if="$gate.isAdminOrUser()">
              <div class="card-header">
                <h3 class="card-title">Role List</h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal" id="AddRole" v-show="can('role_create')">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th v-for="column in columns">
                      <a href="#" @click=sort(column)>{{ column  }}</a>
                      </th>
                    </tr>
                    
                  </thead>
                  <tbody>
                     <tr v-for="role in orderedRoles" :key="role.id">

                      <td>{{role.id}}</td>
                      <td class="text-capitalize">{{role.title}}</td>
                      <td>
                        <ul>
                          <li v-for='permission in role.permissions'>
                            <strong>{{permission.title}}</strong>
                          </li>
                        </ul>
                      <!-- <span v-for="permission in role.permissions" :key="permission.id">{{permission.title}}</span> -->
                      </td>
                      <td >{{role.created_at | formatDate }}</td>
                      <td>
                        <a href="#" @click="editModal(role)" v-show="can('role_edit')" class="editRole">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        
                        <a href="#" @click="deleteRole(role.id)" v-show="can('role_delete')" class="deleteRole">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="roles" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdminOrUser()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Role</h5>
                    <h5 class="modal-title" v-show="editmode">Update role's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div> 

                

                <form @submit.prevent="editmode ? updateRole() : createRole()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Role Title</label>
                            <input v-model="form.title" type="text" name="store_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('title') }">
                            <has-error :form="form" field="title"></has-error>
                        </div>
                        <div class="form-group">

                            <label>Permission</label>
                            <select class="form-control" v-model="form.permission_id" multiple>
                              <option 
                                  v-for="(per,index) in permissions" :key="index"
                                  :value="index"
                                  :selected="index == form.permission_id">{{ per }}</option>
                            </select>
                            <has-error :form="form" field="permission_id"></has-error>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                currentSort:'id',
                currentSortDir:'asc',
                roles : {},
                form: new Form({
                    id : '',
                    title : '',
                    permission_id: [],
                    
                }),
               tempPermission:[],
               permissions:  [],
               sortroles:[],
               columns: ['id', 'title', 'Permission', 'Created', 'Action'],
            }
        },
        methods: {
            can(permissionName)
            {
                switch (permissionName) {
                  case 'role_create':
                  axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                           
                            $('#AddRole').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('#AddRole').hide();
                            return false;
                        }
                    });
                  break;
                  case 'role_edit':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.editRole').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.editRole').hide();
                            return false;
                        }
                        
                    });
                  break;
                  case 'role_delete':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.deleteRole').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.deleteRole').hide();
                            return false;
                        }
                        
                    });
                  break;
                }

            },
            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                  this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
                }
                this.currentSort = s;
              },
            getResults(page = 1) {

                  this.$Progress.start();
                  
                  axios.get('api/role?page=' + page).then(({ data }) => (
                        this.roles = data.data, 
                        this.sortroles=this.roles.data
                        ));

                  this.$Progress.finish();
            },
            updateRole(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('api/role/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadRoles();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(role){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(role);
                this.form.permission_id=[];
                this.tempPermission=[];
                role.permissions.map((permissionId) => { 
                    this.tempPermission.push(permissionId.id);
                });
                this.form.permission_id=this.tempPermission;
               
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteRole(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('api/role/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadRoles();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },
          loadRoles(){
            this.$Progress.start();

            if(this.$gate.isAdminOrUser()){
              axios.get("api/role").then(({ data }) => 
                (
                    this.roles = data.data,
                    this.sortroles=this.roles.data
                ));
            }

            this.$Progress.finish();
          },
          loadPermission(){
              axios.get("/api/permission/list").then(({ data }) => (this.permissions = data.data));
          },
          
          createRole(){

              this.form.post('api/role')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadRoles();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          }

        },
        mounted() {
            console.log('Role Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadRoles();
            this.loadPermission();
            this.$Progress.finish();
        },
        computed: {
            orderedRoles: function () {
              return this.sortroles.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
              });
            }
        },
    }
</script>
