<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Audit log List</h3>

                <div class="card-tools">
                  
           
                </div> 
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th v-for="column in columns">
                      <a href="#" @click=sort(column)>{{ column  }}</a>
                      </th>
                    </tr>
                    
                  </thead>
                  <tbody>
                     <tr v-for="audit in orderedaudits" :key="audit.id">

                      <td>{{audit.id}}</td>
                      <td>{{audit.auditable_type}}</td>
                      <td>{{audit.event}}</td>
                      <td>{{audit.user.email}}</td>
                      <td>{{audit.created_at | formatDate }}</td>
                      <td>{{audit.old_values}}</td>
                      <td>{{audit.new_values}}</td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="audits" @pagination-change-page="getResults"></pagination>
              </div>
              
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- Modal -->
        

        
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                fetchmode: false,
                currentSort:'id',
                currentSortDir:'asc',
                audits : {},
                form: new Form({
                    id : '',
                    shop_id : '',
                }),
                shops: [],
                tag:  '',
                autocompleteItems: [],
                sortaudits:[],
                columns: ['id', 'Model', 'event', 'user', 'Time', 'Old Values', 'New Values'],
            }
        },
        methods: {
          sort:function(s) {
            //if s == current sort, reverse
            if(s === this.currentSort) {
              this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = s;
          },
          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/audit?page=' + page).then(({ data }) => 
                (
                  this.audits = data.data,
                  this.sortaudits=this.audits.data,
                  console.log(this.audits)
                  
                ));

              this.$Progress.finish();
          },
          loadAudits(){

            // if(this.$gate.isAdmin()){
              axios.get("api/audit").then(({ data }) => 
                (
                  
                  this.audits = data.data,
                  this.sortaudits=this.audits.data,
                  console.log(this.audits)
                ));
              
            // }
          },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadAudits();

            this.$Progress.finish();
        },
        computed: {
            orderedaudits: function () {
              return this.sortaudits.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
              });
            }
        },
        
    }
</script>
