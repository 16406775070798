<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <div class="container">
                <div class="row">
                  <div class="col-sm">
                    <div class="kt-form__label">
                      <label>Order No:</label>
                    </div>
                    <input type="text" class="form-control" placeholder=" Order ID"  @change="changeFilterOption('order')" id="generalSearch" v-show="!loadingOrder" v-model="filter.order">
                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                      <span><i class="la la-search"></i></span>
                    </span>
                  </div>
                  <div class="col col-lg">
                    <div class="kt-form__label">
                      <label>DateRange:</label>
                    </div>
                    <template>
                      <date-range-picker
                        v-model="filter.dateRange"
                        :locale-data="{ firstDay: 0, format: 'yyyy-mm-dd' }"
                        @update="changeFilterOption('dateRange')"
                      ></date-range-picker>
                    </template>
                  </div>
                  <div class="col-sm">
                    <div class="kt-form__label">
                      <label>Customer(Name or Email):</label>
                    </div>
                    <input type="text" class="form-control" placeholder=" Customer"  @change="changeFilterOption('customer')" id="customer" v-model="filter.customer" v-show="!loadingOrder">
                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                      <span><i class="la la-search"></i></span>
                    </span>
                  </div>
                  <div class="col-sm">
                    <div class="kt-form__label">
                      <label>PaymentStatus:</label>
                    </div>
                      <select class="form-control selectpicker" id="kt_form_status"  tabindex="-98" v-model="filter.status" @change="changeFilterOption('status')">
                        <option value="paid">Paid</option>
                        <option value="pending">Pending</option>
                        <option value="voided">Voided</option>
                        <option value="Refunded">Refunded</option>
                        <option value="Unpaid">Unpaid</option>
                      </select> 
                  </div>
                  <div class="col-sm">
                    <div class="kt-form__label">
                      <label>FullfillmentStatus:</label>
                    </div>
                      <select class="form-control selectpicker" id="kt_form_status"  tabindex="-98" v-model="filter.fullfillment" @change="changeFilterOption('fullfillment')">
                        <option value="Fulfilled">Fulfilled</option>
                        <option value="Unfulfilled">Unfulfilled</option>
                        <option value="Partially fulfilled">Partially fulfilled</option>
                        <option value="Scheduled">Scheduled</option>
                        <option value="On hold">On hold</option>
                      </select> 
                  </div>
                  <div class="col-sm">
                    <div class="kt-form__label">
                      <label></label>
                    </div>
                    <button type="button" class="btn btn-sm btn-primary" @click="clearSearch" style="margin-top: 15px;">
                      <i class="fa fa-times-circle"></i>
                      Clear Search
                  </button>
                  </div>
                </div>
            </div>
            <br/>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Order List</h3>
                
                <div class="card-tools">
                  
                  <button v-show="can('order_fetch')" type="button" class="btn btn-sm btn-primary" @click="newModal" id="fetchOrder">
                      <i class="fa fa-plus-square"></i>
                       Fetch Order
                  </button>
                </div> 
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th v-for="column in columns">
                      <a href="#" @click=sort(column)>{{ column  }}</a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="order in orderedUsers" :key="order.id">

                      <td>{{order.id}}</td>
                      <td class="text-capitalize">{{order.order_no}}</td>
                      <td class="text-capitalize">{{order.order_date | formatDate }}</td>
                      <td class="text-capitalize">
                        <ul>
                          <li v-for="(shop,index) in shops" :key="index"
                                  :value="index"
                                  :selected="index == order.store_id">
                            {{shop}}
                          </li>
                        </ul></td>
                      <td class="text-capitalize">
                        <p v-if="order.customer">{{order.customer.firstName}}</p>
                      </td>
                      <td >{{order.customer_email}}</td>
                      <td class="text-capitalize">{{order.order_total}}</td>
                      <td class="text-capitalize">{{order.payment_status}}</td>
                      <td class="text-capitalize">{{order.fullfilment_status}}</td>
                      
                      <td>
                        <router-link :to="{name: 'orderView', params: { id: order.id}}" class="fa fa-eye blue viewOrder" v-show="can('order_view')">
                        </router-link>
                        <router-link :to="{name: 'orderEdit', params: { id: order.id}}" class="fa fa-edit blue editOrder" v-show="can('order_edit')">
                        </router-link>
                        <a href="#" @click="returnOrder(order.id)" title="Return Order" v-show="can('order_return')" class="returnOrder">
                            <i class="fa fa-undo blue"></i>
                        </a>
                      </td>
                      
                      
                    </tr>
                  </tbody>
                </table>
                
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="orders" @pagination-change-page="getResults"></pagination>
              </div>
              
            </div>
            <!-- /.card -->
          </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Fetch Shopify Order</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div> 
                <form @submit.prevent="fetchOrder()">
                    <div class="modal-body">
                        <div class="form-group">

                            <label>Select Shopify Store</label>
                            <select class="form-control" v-model="form.shop_id">
                              <option 
                                  v-for="(shop,index) in shops" :key="index"
                                  :value="index"
                                  :selected="index == form.shop_id">{{ shop }}</option>
                            </select>
                            <has-error :form="form" field="shop_id"></has-error>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">FetchOrder</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>

        
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import DateRangePicker from 'vue2-daterange-picker';
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
    export default {
      components: {
          VueTagsInput,
          DateRangePicker,
        },
        data () {
            return {
                sortKey: 'id',
                reverse: false,
                currentSort:'id',
                currentSortDir:'asc',
                returnOrderid:{
                  id:'',
                },
                fetchmode: false,
                orders : {},
                form: new Form({
                    id : '',
                    shop_id : '',
                }),
                shops: [],
                sortorders:[],
                tag:  '',
                autocompleteItems: [],
                filter:{
                  date:"",  //2020-05-26
                  order:"",
                  customer:"",
                  payment:"",
                  fullfillment:"",
                  status:"",
                  dateRange: {
                    startDate: null,
                    endDate: null,
                  },
                  start:"",
                  end:"",
                },
                loadingOrder:false,
                columns: ['id', 'order_no', 'Order date', 'Store', 'CustomerName', 'Email', 'Total', 'Payment Status', 'Fulfillment'],
                loadingSortOrder:false,
                

                
            }
        },
        methods: {
          can(permissionName)
            {
                switch (permissionName) {
                  case 'order_fetch':
                  axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                           
                            $('#fetchOrder').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('#fetchOrder').hide();
                            return false;
                        }
                    });
                  break;
                  case 'order_edit':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.editOrder').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.editOrder').hide();
                            return false;
                        }
                        
                    });
                  break;
                  case 'order_view':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.viewOrder').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.viewOrder').hide();
                            return false;
                        }
                        
                    });
                  break;
                  case 'order_return':
                    axios.get(`api/permission/${permissionName}`)
                    .then((response)=> {
                        console.log(response.data.data);
                        if(response.data.data==true){
                            //console.log("In True");
                            $('.returnOrder').show();
                            return true;
                        }else{
                            //console.log("In false");
                            
                            $('.returnOrder').hide();
                            return false;
                        }
                        
                    });
                  break;
                  
                }

            },
          clearSearch(){
             
              this.filter.dateRange.startDate=null;
              this.filter.dateRange.endDate=null;
              this.filter.start=null;
              this.filter.fullfillment=null;
              this.filter.end=null;
              this.filter.order=null;
              this.filter.customer=null;
              this.filter.status=null;
              this.loadingSortOrder=false;
              this.loadOrders();
              Toast.fire({
                        icon: 'success',
                        title: 'Search cleared'
                  });
          },
          sort:function(s) {
            //if s == current sort, reverse
            if(s === this.currentSort) {
              this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = s;
          },
          changeFilterOption(type){
                switch (type) {
                  case 'order':
                    
                  break;
                  case 'date':
                    
                  break;
                  case 'fullfillment':
                    
                  break;
                  case 'customer':
                    
                  break;
                  case 'status':
                    
                  break;
                  case 'dateRange':
                    //alert('date Range');
                    this.filter.start=this.convert(this.filter.dateRange.startDate);
                    this.filter.end=this.convert(this.filter.dateRange.endDate);
                  break;
                  
                }
              this.loadingSortOrder=true;
              console.log(this.filter);
              this.fetchDataFromApi(false,false);
            },

          getResults(page = 1) {

              this.$Progress.start();
              if(this.loadingSortOrder)
              {
                
                axios.post('api/order/filterList?page=' + page, this.filter).then(({ data }) => 
                (
                  this.orders = data.data,
                  this.sortorders=this.orders.data,
                  this.shops = data.message 
                ));

              }else{
                axios.get('api/order?page=' + page).then(({ data }) => 
                (
                  this.orders = data.data,
                  this.sortorders=this.orders.data,
                  this.shops = data.message 
                ));
              }
              

              this.$Progress.finish();
          },
          loadOrders(){

            // if(this.$gate.isAdmin()){
              axios.get("api/order").then(({ data }) => 
                (
                  this.orders = data.data,
                  this.sortorders=this.orders.data,
                  this.shops = data.message
                ));
              
            // }
          },
          returnOrder(orderId)
          {
            this.$Progress.start();
            console.log("In Return Order");
            console.log(orderId);
            this.returnOrderid=orderId;
            axios.get("/api/order/returnOrder/"+orderId)
                  .then((response)=>{
                      console.log(response);
                      Toast.fire({
                            icon: 'success',
                            title: response.data.message
                      });
                      //return false;
                      this.$Progress.finish();
                      return false;
                      this.$router.push({path: '/orders'})

                  })
                  .catch(()=>{

                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                  })
          },
          newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
          },
          fetchOrder(){
             this.$Progress.start();
              this.fetchmode=true
              $('#addNew').modal('hide');
              this.form.post('api/order/fetchShopifyOrder')
              .then(({ data }) => 
                (
                  console.log(data),
                  Toast.fire({
                        icon: 'success',
                        title: data.message
                  }),
                  this.fetchmode=false
                  //this.categories = data.data
               ));
              this.loadOrders();
              this.$Progress.finish();
          },
          fetchDataFromApi(param=true,isNext=true){

            console.log("In Fetch Data filter function");
            console.log(this.filter);
              axios.post("/api/order/filterList", this.filter)
              .then((response)=>{
                  
                  this.orders = response.data.data,
                  this.sortorders=this.orders.data,
                  this.$Progress.finish();
                  Toast.fire({
                        icon: 'success',
                        title: 'Filter Order List'
                  });
                  
              })
              .catch(()=>{
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          convert(str) {
            var date = new Date(str),
              mnth = ("0" + (date.getMonth() + 1)).slice(-2),
              day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
          }
          
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadOrders();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
            orderedUsers: function () {
              return this.sortorders.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
              });
            }
        },
    }
</script>
